import axios from "axios";
import ElementUI from 'element-ui';

const instance = axios.create({
    // baseURL: "http://192.168.3.108/api.php",
    // baseURL: "http://47.91.18.224/api.php",
    // baseURL: "http://daichun.com/api.php",
    baseURL: "https://admin.esenjapan.com/api.php",
    // baseURL: "https://chunshan.puyan.51pride.com/api.php",
});


// 添加请求拦截器
instance.interceptors.request.use(
    function(config) {
        console.log(config)
        // 在发送请求之前做些什么
        // 在请求头中添加Authorization头部
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.token = `${token}`;
        }
        console.log('22222----------------2', config)
        return config;
    },
    function(error) {
        // 对请求错误做些什么
        return Promise.reject(error.data);
    }
);

// 添加响应拦截器
instance.interceptors.response.use(
    function(response) {
        console.log(response)
        if (response.status === 200) {
            console.log(response.data.code)
            if (response.data.code === 1) {
                // 对响应数据做点什么 console.log(11)
                return Promise.resolve(response.data);
            } else {
                ElementUI.Message({
                    message: response.data.msg,
                    type: 'warning'
                });
                return Promise.reject(response.data);
            }
        } else if (response.status === 401) {
            // 对响应数据做点什么 console.log(11)
            window.location.href = "/login";
        } else {
            ElementUI.Message({
                message: '网络异常, 请稍后再试',
                type: 'warning'
            });
            return Promise.reject(response.data);
        }
    },
    function(error) {
        console.log("error")

        if (error.response.status === 401) {
            // 对响应数据做点什么 console.log(11)
            window.location.href = "/login";
        }
        // 对响应错误做点什么
        return Promise.reject(error.data);
    }
);

export default instance;