<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {},
    }
</script>

<style>
    @import '@/static/css/app.css';

   /* #translateSelectLanguage {
        display: none;
    } */
</style>