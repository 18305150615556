import Vue from 'vue'
import Router from 'vue-router'
import createWebHashHistory  from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

var router = new Router({
    // mode: 'hash',
    mode: 'history',
    routes: [{
            path: '/',
            name: 'mainIndex',
            component: () => import('@/pages/main/index.vue'),
            meta: {
                title: '首页',
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login/login.vue'),
            meta: {
                title: '登录',
            }
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('@/pages/index/index.vue'),
            meta: {
                title: '首页',
            },
            children: [{
                    path: '/yaHu',
                    name: 'yaHu',
                    component: () => import('@/pages/main/yaHu.vue'),
                    meta: {
                        title: '雅虎购物',
                    }
                }, {
                    path: '/leTian',
                    name: 'leTian',
                    component: () => import('@/pages/main/leTian.vue'),
                    meta: {
                        title: '乐天购物',
                    }
                },
                {
                    path: '/rakutenSearch',
                    name: 'rakutenSearch',
                    component: () => import('@/pages/main/rakutenSearch.vue'),
                    meta: {
                        title: '产品列表',
                    }
                },
                {
                    path: '/yahooSearch',
                    name: 'yahooSearch',
                    component: () => import('@/pages/main/yahooSearch.vue'),
                    meta: {
                        title: '产品列表',
                    }
                },
                {
                    path: '/amazon',
                    name: 'amazon',
                    component: () => import('@/pages/main/amazon.vue'),
                    meta: {
                        title: 'Amazon',
                    }
                }, {
                    path: '/yahooGoodDetail',
                    name: 'yahooGoodDetail',
                    component: () => import('@/pages/main/yahooGoodDetail.vue'),
                    meta: {
                        title: '雅虎商品详情',
                    }
                }, {
                    path: '/count',
                    name: 'count',
                    component: () => import('@/pages/main/count.vue'),
                    meta: {
                        title: '雅虎商品详情', //计算
                    }
                }, {
                    path: '/letianGoodDetail',
                    name: 'letianGoodDetail',
                    component: () => import('@/pages/main/letianGoodDetail.vue'),
                    meta: {
                        title: '乐天商品详情',
                    }
                }, {

                    path: '/subscriptionPay',
                    name: 'subscriptionPay',
                    component: () => import('@/pages/main/subscriptionPay.vue'),
                    meta: {
                        title: '订阅支付',
                    }
                }, {
                    path: '/noviceGuide',
                    name: 'noviceGuide',
                    component: () => import('@/pages/guide/noviceGuide.vue'),
                    meta: {
                        title: '新手指南',
                    }
                }, {
                    path: '/notice',
                    name: 'notice',
                    component: () => import('@/pages/guide/notice.vue'),
                    meta: {
                        title: '重要公告',
                    }
                },
                {

                    path: '/blog',
                    name: 'blog',
                    component: () => import('@/pages/guide/blog.vue'),
                    meta: {
                        title: '博客',
                    }
                },
                {
                    path: '/blogDetails',
                    name: 'blogDetails',
                    component: () => import('@/pages/guide/blogDetails.vue'),
                    meta: {
                        title: '博客', //博客详情
                    }
                },

                {
                    path: '/aboutUs',
                    name: 'aboutUs',
                    component: () => import('@/pages/guide/aboutUs.vue'),
                    meta: {
                        title: '关于我们', //关于我们
                    }
                },
                {
                    path: '/bottomContent',
                    name: 'bottomContent',
                    component: () => import('@/pages/guide/bottomContent.vue'),
                    meta: {
                        title: '详情', //
                    }

                },
                {
                    path: '/serviceDescription',
                    name: 'serviceDescription',
                    component: () => import('@/pages/guide/serviceDescription.vue'),
                    meta: {
                        title: '服务说明',
                    }

                },
                {
                    path: '/personalCenter',
                    name: 'personalCenter',
                    component: () => import('@/pages/guide/personalCenter.vue'),
                    meta: {
                        title: '个人中心',
                    },
                    redirect: 'cart',
                    children: [{
                        path: '/cart',
                        name: 'cart',
                        component: () => import(
                            '@/pages/personalCenter/cart.vue'),
                        meta: {
                            title: '购物车',
                        }
                    }, {

                        path: '/contactService',
                        name: 'contactService',
                        component: () => import(
                            '@/pages/personalCenter/contactService.vue'),
                        meta: {
                            title: '联系客服',
                        }
                    }, {

                        path: '/messageDynamics',
                        name: 'messageDynamics',
                        component: () => import(
                            '@/pages/personalCenter/messageDynamics.vue'),
                        meta: {
                            title: '消息动态',
                        }
                    }, {

                        path: '/orderPackage',
                        name: 'orderPackage',
                        component: () => import(
                            '@/pages/personalCenter/orderPackage.vue'),
                        meta: {
                            title: '商品列表',
                        }
                    }, {

                        path: '/orderList',
                        name: 'orderList',
                        component: () => import(
                            '@/pages/personalCenter/orderList.vue'),
                        meta: {
                            title: '订单列表',
                        }
                    }, {
                        path: '/orderDetail',
                        name: 'orderDetail',
                        component: () => import(
                            '@/pages/personalCenter/orderDetail.vue'),
                        meta: {
                            title: '订单详情',
                        }
                    }, {

                        path: '/detailsWithdrawal',
                        name: 'detailsWithdrawal',
                        component: () => import(
                            '@/pages/personalCenter/detailsWithdrawal.vue'),
                        meta: {
                            title: '明细 · 提款',
                        }
                    }, {
                        path: '/account',
                        name: 'account',
                        component: () => import(
                            '@/pages/personalCenter/account.vue'),
                        meta: {
                            title: '账号设置',
                        }
                    }]
                }
            ]
        },
        {
            name: 'orderDayinDetail',
            component: () => import('@/pages/personalCenter/orderDayinDetail.vue'),
            path: '/orderDayinDetail',
        },
    ]
})

// router.beforeEach((to,from,next)=>{
//     let tokenStr = window.sessionStorage.getItem('token')
//     if(!tokenStr) return next('/home')
//     next()
// })



export default router